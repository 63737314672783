/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/prefer-default-export */
import React, {
  createContext, useContext, useEffect, useCallback, useReducer,
} from 'react'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import { fetchEPG } from '../services/account/epg.service.tsx'
import { getCurrentDateTime, checkSameDate, getCurrentMinute } from '../utils/dateUtils'

const ACTIONS = {
  UPDATE: 'update',
  RESET: 'reset',
}

const initVals = {
  actualMinute: 0,
  scheduleJson: [],
  scrollWidth: 12,
  channelHeight: 90,
  hoursHeight: 20,
  minuteInPixels: 5,
  paddingSize: 2,
  quantToShow: 0,
  todaysDate: null,
  selectedDate: getCurrentDateTime(),
  userIsOnToday: true,
}

const reduce = (state, event) => {
  switch (event.type) {
    case ACTIONS.UPDATE:
      return {
        ...state,
        ...event.payload,
      }
    case ACTIONS.RESET:
      return initVals
    default:
      return state
  }
}

const ScheduleContext = createContext({
  ...initVals,
  fetchAnotherDate: () => {},
})

export const FetchScheduleInfo = ({ children, handleSkeleton }) => {
  const [state, dispatch] = useReducer(reduce, initVals)
  const channelHeightInBiggerScreen = 60

  // handle image aspect ratio
  useEffect(() => {
    const handleResize = () => {
      if (window.visualViewport.width >= 1080) {
        dispatch({
          type: ACTIONS.UPDATE,
          payload: {
            minuteInPixels: (window.screen.width * state.minuteInPixels) / 1080,
            hoursHeight: (window.screen.width * state.hoursHeight) / 1080,
            channelsHeight: (window.screen.width * channelHeightInBiggerScreen) / 1080,
            paddingSize: (window.screen.width * state.paddingSize) / 1080,
          },
        })
      } else {
        dispatch({
          type: ACTIONS.UPDATE,
          payload: {
            minuteInPixels: 5,
            hoursHeight: 20,
            channelsHeight: 90,
            paddingSize: 2,
          },
        })
      }
    }

    handleResize() // Chech resize in first render
    window.addEventListener('resize', handleResize) // Change resize on screen change
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  /**
   * Function to get screen empty space
   * EPG will occupy all screen blank space
   * @param {bool} isPipModeActive Bolean to know if full screen EPG should be showed
   */
  const getEmptySpaceHeight = (isPipModeActive) => {
    let emptyPixels

    // If isPipModeActive, only take into consideration navbar + hours + scrollWidth height
    if (isPipModeActive || !document.getElementsByClassName('player-container')[0]) {
      emptyPixels = (
        window.visualViewport.width >= 1080 ? window.visualViewport.height : window.innerHeight)
      - (document.getElementsByClassName('navbar')[0].clientHeight
      + state.hoursHeight
      + state.scrollWidth
      )
    } else {
      // Else take into consideration navbar + hours + scrollWidth + player height
      emptyPixels = (
        window.visualViewport.width >= 1080 ? window.visualViewport.height : window.innerHeight)
        - (document.getElementsByClassName('player-container')[0].clientHeight
        + document.getElementsByClassName('navbar')[0].clientHeight
        + state.hoursHeight
        + state.scrollWidth
        )
    }

    // If there are dates (EPG tab)
    if (document.getElementsByClassName('dates')[0]) {
      if (window.screen.width <= 500) {
        emptyPixels -= (document.getElementsByClassName('dates')[0].clientHeight + 20)
      } else {
        emptyPixels -= (document.getElementsByClassName('dates')[0].clientHeight)
      }
    }

    // If there is responsive navbar (phone)
    if (document.getElementsByClassName('navbar-responsive')[0]) {
      emptyPixels -= document.getElementsByClassName('navbar-responsive')[0].clientHeight
    }

    if (window.visualViewport.width >= 1080) emptyPixels -= state.scrollWidth

    // Empty pixels will be divided by channels height and result will be rounded to one decimal
    dispatch({
      type: ACTIONS.UPDATE,
      payload: {
        quantToShow: ((emptyPixels / state.channelHeight) * 10) / 10,
      },
    })
  }

  const getScrollbarWidth = () => {
    let div = getScrollbarWidth.width
    let { width } = getScrollbarWidth
    if (width === undefined) {
      div = document.createElement('div')
      div.innerHTML = '<div style="width:50px;height:50px;position:absolute;left:-50px;top:-50px;overflow:auto;"><div style="width:1px;height:100px;"></div></div>'
      div = div.firstChild
      document.body.appendChild(div)
      // eslint-disable-next-line no-multi-assign
      width = getScrollbarWidth.width = div.offsetWidth - div.clientWidth
      document.body.removeChild(div)
    }
    return width
  }

  /**
   * Get EPG by selected date
   * @param {*} date Date to fecth from EPG
   */
  const fetchEPGByDate = useCallback(async (date, isSkeletonRequired) => {
    if (isSkeletonRequired) handleSkeleton(true)

    dispatch({
      type: ACTIONS.UPDATE,
      payload: {
        selectedDate: date,
        userIsOnToday: state.todaysDate ? checkSameDate(date, state.todaysDate) : true,
      },
    })

    // Fetching full schedule once
    await fetchEPG(date).then(async (res) => {
      const [statusCode, response] = res

      dispatch({
        type: ACTIONS.UPDATE,
        payload: {
          actualMinute: getCurrentMinute(),
          scheduleJson: statusCode === 200 ? response : [],
        },
      })

      if (isSkeletonRequired) handleSkeleton(false)
    })
  }, [state.todaysDate])

  useEffect(() => {
    const getActualDate = getCurrentDateTime()

    dispatch({
      type: ACTIONS.UPDATE,
      payload: {
        scrollWidth: getScrollbarWidth(),
        todaysDate: getActualDate,
      },
    })

    fetchEPGByDate(getActualDate, false)
  }, [i18next.language])

  useEffect(() => {
    // Check every minute if date has changed
    const timer = setTimeout(() => {
      const testingDate = getCurrentDateTime()

      const isNewDate = !checkSameDate(state.todaysDate, testingDate)
      // If its a new date
      if (isNewDate) {
        fetchEPGByDate(testingDate)
      }

      // Change the currently date schedule and set new date
      dispatch({
        type: ACTIONS.UPDATE,
        payload: {
          actualMinute: isNewDate ? 0 : getCurrentMinute(),
          todaysDate: testingDate,
        },
      })
    }, 60 * 1000)
    return () => {
      clearTimeout(timer)
    }
  }, [state.todaysDate, state.scheduleJson])

  return (
    <ScheduleContext.Provider value={{
      ...state,
      fetchEPGByDate,
      getEmptySpaceHeight,
    }}
    >
      { children }
    </ScheduleContext.Provider>
  )
}

FetchScheduleInfo.propTypes = {
  children: PropTypes.element,
  handleSkeleton: PropTypes.func,
}

FetchScheduleInfo.defaultProps = {
  children: undefined,
  handleSkeleton: () => {},
}

export const returnScheduleContext = () => {
  const context = useContext(ScheduleContext)
  if (!context) return 'There is not context'
  return context
}

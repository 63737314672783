import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { I18nextProvider } from 'react-i18next'
import {
  BrowserRouter as Router,
} from 'react-router-dom'

import i18next from './utils/i18n'
import { persistor, store } from './store/index'
import App from './App'

const root = (
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <App />
        </Router>
      </PersistGate>
    </Provider>
  </I18nextProvider>
)
ReactDOM.render(root, document.getElementById('root'))

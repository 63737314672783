/* eslint-disable no-use-before-define */
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import heroContainers from '../../../../assets/json/heroCarousel.json'
import arrow from '../../../../assets/images/arrowIcon.svg'
import backArrow from '../../../../assets/images/back-arrow.svg'
import forwardArrow from '../../../../assets/images/forward-arrow.svg'
import Button from '../../extraComponents/button/Button'
import useScreen from '../../../utils/Observer'
import Clock from './Clock'
import './hero.scss'

const Hero = ({ buttonFunc }) => {
  const heroRef = React.useRef()
  const [active, setActive] = React.useState(0)
  const [isSquareAspect, setIsSquareAspect] = React.useState(false)
  const { t } = useTranslation('common')
  const previewContainerRef = React.useRef()
  const HeroObserver = useScreen(previewContainerRef, 0.5)

  const [start, setStart] = useState([])
  const autoScroll = useRef(undefined)
  const timers = useRef(0)

  useEffect(() => {
    if (HeroObserver) {
      autoScroll.current = setInterval(() => {
        setActive((oldActive) => (oldActive + 1) % Math.ceil(heroContainers.images.length))
      }, 3000)
    }
    return () => clearInterval(autoScroll.current)
  }, [HeroObserver])

  useEffect(() => {
    if (heroContainers?.images) {
      const startArr = heroContainers.images
        .map((element) => (element.start ? element.start : null))
      setStart(startArr)
      autoScroll.current = setInterval(() => {
        setActive((oldActive) => (oldActive + 1) % heroContainers.images.length)
      }, 5000)
    }
    return () => {
      clearInterval(autoScroll.current)
      autoScroll.current = undefined
    }
  }, [heroContainers])

  useEffect(() => {
    heroRef.current.scrollLeft = heroRef.current.offsetWidth * active
  }, [active])

  // handle image aspect ratio
  useEffect(() => {
    const handleResize = () => {
      if (window.screen.availWidth < 950 && window.screen.availHeight > 500) {
        setIsSquareAspect(true)
      } else {
        setIsSquareAspect(false)
      }
    }

    handleResize() // Change ia on first render
    window.addEventListener('resize', handleResize) // Change ia on screen change
    window.addEventListener('touchstart', handleTouchStart, { passive: true })
    window.addEventListener('mousewheel', handleTrackpadStart, { passive: true })
    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('touchstart', handleTouchStart, { passive: true })
      window.removeEventListener('mousewheel', handleTrackpadStart, { passive: true })
    }
  }, [])

  let trackpadEnabled = true

  const handleTrackpadStart = ({ target, deltaX }) => {
    if (!trackpadEnabled || Math.abs(deltaX) < 30 || target.classList[0] !== 'hero-item') return
    if (deltaX > 30) {
      trackpadEnabled = false
      setTimeout(() => {
        trackpadEnabled = true
      }, 500)
      goNext()
    } else if (deltaX < -30) {
      trackpadEnabled = false
      setTimeout(() => {
        trackpadEnabled = true
      }, 500)
      goBack()
    }
  }

  let positionX = 0

  const handleTouchStart = ({ changedTouches, target }) => {
    if (target.classList[0] !== 'hero-item') return
    handleTimer()
    positionX = changedTouches[0]?.clientX
    window.addEventListener('touchend', handleTouchEnd)
  }

  const handleTouchEnd = ({ changedTouches }) => {
    const horizontalMovement = (changedTouches[0]?.clientX || 0) - positionX
    if (horizontalMovement > 50) {
      goBack()
    } else if (horizontalMovement < -50) {
      goNext()
    }
    window.removeEventListener('touchend', handleTouchEnd)
  }

  const handleTimer = () => {
    timers.current += 1
    clearInterval(autoScroll.current)
    autoScroll.current = undefined
    setTimeout(() => {
      timers.current -= 1
      if (timers.current === 0) {
        // Resuming the auto scroll after 5 seconds
        setActive((oldActive) => (oldActive + 1) % heroContainers.images.length)
        autoScroll.current = setInterval(() => {
          setActive((oldActive) => (oldActive + 1) % heroContainers.images.length)
        }, 5000)
      }
    }, 5000)
  }

  const goNext = () => {
    handleTimer()
    // Move to hero item to the right
    setActive((oldActive) => (oldActive + 1) % heroContainers.images.length)
  }

  const goBack = () => {
    handleTimer()
    // Move to hero item to the left
    setActive((oldActive) => (oldActive !== 0 ? oldActive - 1 : heroContainers.images.length - 1))
  }

  const handleLive = (item) => {
    if (Date.parse(item.start)) {
      if ((Date.parse(item.start) < Date.parse(new Date())) && item.end > (new Date()).toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
      })) {
        return true
      }
    }
    if (item.start < (new Date()).toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
    }) && item.end > (new Date()).toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
    })) {
      return true
    }
    return false
  }

  return (
    <div className="hero" ref={previewContainerRef}>
      <div className="hero-arrows">
        <button type="button" onClick={() => goBack()} className="left-arrow">
          <img alt="left-arrow" src={backArrow} />
        </button>
        <button type="button" onClick={() => goNext()} className="right-arrow">
          <img alt="right-arrow" src={forwardArrow} />
        </button>
      </div>
      <div className="hero-list" ref={heroRef}>
        {
          start && heroContainers?.images?.map((item, index) => (
            <button
              key={item.id}
              alt="hero-item"
              className="hero-item"
              type="button"
              style={{ left: `${index * 100}%`, background: `linear-gradient(87.74deg, #000000 1.74%, rgba(0, 0, 0, 0.78125) 7.25%, rgba(0, 0, 0, 0.220219) 57.83%, rgba(0, 0, 0, 0.118849) 75.35%, rgba(0, 0, 0, 0) 89.37%), url(${isSquareAspect ? item?.ia1x1 : item?.ia3x1})` }}
            >
              <div className="hero-description-container">
                {start && !handleLive(item) && item.special
                  ? <Clock item={item} dt={start[index]} />
                  : null}
                <div className="hero-description-subitems">
                  { handleLive(item)
                    ? (
                      <span className="hero-live">
                        {t('common.live')}
                      </span>
                    )
                    : null}
                  <img src={item.channel.image} alt="hero channel" />
                </div>
                <h1 className="hero-title">
                  {item.name}
                </h1>
                { start && !handleLive(item) && !item.special
                  ? <Clock item={item} dt={start[index]} />
                  : null }
              </div>
            </button>
          ))
        }
      </div>
      <Button classButton="go-to-arrow" icon={[arrow]} onClick={buttonFunc} iconAlt="down arrow" />
    </div>
  )
}

Hero.propTypes = {
  buttonFunc: PropTypes.func.isRequired,
}

export default Hero

import React from 'react'
import './navbar.scss'
import { useTranslation } from 'react-i18next'
import logoIcon from '../../../../assets/images/logo.svg'
import Button from '../button/Button'

const Navbar = () => {
  const { t } = useTranslation('common')
  return (
    <div className="no-auth-navbar">
      <Button classButton="logo-button" icon={[logoIcon]} iconAlt="logo" buttonType="logo" />
      <Button classButton="sign-in-button" text={t('links.Sign_In')} route="signin" />
    </div>
  )
}
export default Navbar

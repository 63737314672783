/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Auth, Login } from '@cleeng/mediastore-sdk'
import logoIcon from '../../../../assets/images/logo.svg'
import Button from '../../extraComponents/button/Button'
import { authActions } from '../../../store/auth/auth-slice'
import { fetchUserSubscription } from '../../../store/auth/auth-actions'
import './signin.scss'
import { InterSectionObserver } from '../../../utils/IntersectionObserver'

const SignIn = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
  const isSubscribed = useSelector((state) => state.auth.isSubscribed)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const lazyLoadVideo = () => {
    const lazyVideo = document.getElementById('lazyVideo') // TAG SRC NEEDS TO BE EMPTY
    InterSectionObserver(lazyVideo)
  }

  const successLoginHandler = async () => {
    // Note:Login is handled by the Login component
    // const customer = await getCustomer()
    // should save customer in state
    Auth.isLogged()
    dispatch(fetchUserSubscription()).then((subscription) => {
      dispatch(authActions.login())
      if (!subscription) {
        navigate('/signUp', {
          state: {
            purchaseSubscription: true,
          },
        })
      }
    })
  }

  useEffect(() => {
    if (isLoggedIn && isSubscribed) {
      navigate('/liveTV')
    } else lazyLoadVideo() // THE VIDEO IS LOADED ONLY IF USER STAYS IN SIGNIN
  }, [isLoggedIn, isSubscribed])

  return (
    <div className="signIn">
      <Button icon={[logoIcon]} iconAlt="logo" classButton="logo-button" buttonType="logo" />
      <div className="signIn__container">
        <video className="signIn__video" id="lazyVideo" poster="/assets/images/poster.png" muted autoPlay>
          <source src="" type="video/webm" />
        </video>
        <Login
          onSuccess={successLoginHandler}
          onRegisterClick={() => navigate('/signup')}
          onPasswordResetClick={() => navigate('/forgotPassword')}
        />
      </div>
    </div>
  )
}

export default SignIn

/* eslint-disable no-console */
import React from 'react'
import PropTypes from 'prop-types'
import './popup.scss'
import openCloseIcon from '../../../../assets/images/cancel.svg'

const Popup = ({
  buttonOk, buttonCancel, buttonXfunc, content,
}) => (
  <div className="popup">
    <div className="popup-container">
      <button className="popup-x" onClick={buttonXfunc} type="button">
        <img alt="x-icon" src={openCloseIcon} />
      </button>
      <h1>{content.title}</h1>
      <div className="popup-info">{content.info}</div>
      <button className="popup-ok-btn" type="button" onClick={buttonOk.func}>{buttonOk.text}</button>
      {buttonCancel !== null
        ? <button className="popup-cancel-btn" type="button" onClick={buttonCancel.func}>{buttonCancel.text}</button>
        : null}
    </div>
  </div>
)

Popup.propTypes = {
  buttonOk: PropTypes.shape({
    text: PropTypes.string.isRequired,
    func: PropTypes.func.isRequired,
  }).isRequired,
  buttonCancel: PropTypes.shape({
    text: PropTypes.string.isRequired,
    func: PropTypes.func.isRequired,
  }),
  buttonXfunc: PropTypes.func.isRequired,
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    info: PropTypes.string.isRequired,
  }).isRequired,
}

Popup.defaultProps = {
  buttonCancel: null,
}

export default Popup

/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import carouselsJson from '../../../../assets/json/expandingCarousel.json'
import Button from '../../extraComponents/button/Button'
import arrow from '../../../../assets/images/arrowIcon.svg'
import useScreen from '../../../utils/Observer'
import './expandingCarousel.scss'
// import { fetchCurrentContent } from '../../../store/content/content-actions'

const ExpandingCarousel = ({ buttonFunc }) => {
  const { t } = useTranslation('common')
  const previewRef = React.useRef()
  const previewContainerRef = React.useRef()
  const observer = useScreen(previewContainerRef, 1)

  const [active, setActive] = React.useState(0)
  const [carouselContainer, setcarouselContainer] = useState([])
  const [isPhone, setIsPhone] = React.useState(false)
  const navigate = useNavigate()
  // const dispatch = useDispatch()

  useEffect(() => {
    setcarouselContainer(carouselsJson)
  }, [carouselsJson])

  useEffect(() => {
    previewRef.current.scrollLeft = Math.ceil((isPhone
      ? 347 : 0.32 * previewRef.current.offsetWidth)
      * active)
  }, [active])

  // handle image aspect ratio
  useEffect(() => {
    const handleResize = () => {
      if (window.screen.availWidth < 500) {
        setIsPhone(true)
      } else {
        setIsPhone(false)
      }
    }

    handleResize() // Change screen on first render
    window.addEventListener('resize', handleResize) // Change screen variable on screen change
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const redirectTo = () => {
    navigate('/signIn')
  }

  return (
    <div className="expanding" ref={previewContainerRef}>
      <div className={`${!isPhone ? 'expanding-list' : 'list'}`} ref={previewRef}>
        {
          carouselContainer?.map((item, index) => (
            <button
              key={item.id}
              alt="expanding-item"
              onClick={() => redirectTo()}
              type="button"
              style={{ background: `linear-gradient(${!isPhone ? '180deg' : '-90deg'}, transparent 0%, #101010 ${!isPhone ? '90%' : '85%'}), url(${item.image})` }}
            >
              <div className="item-logo">
                <img src={item.logo} alt={item.id} />
              </div>
            </button>
          ))
        }
      </div>
      <Button classButton="go-to-arrow" icon={[arrow]} onClick={buttonFunc} iconAlt="down arrow" />
    </div>
  )
}

ExpandingCarousel.propTypes = {
  buttonFunc: PropTypes.func.isRequired,
}

export default ExpandingCarousel

import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  CheckoutConsents, Purchase, Register, Auth,
} from '@cleeng/mediastore-sdk'
import { authActions } from '../../../store/auth/auth-slice'
import ChangePlan from '../../settingsRelated/changePlan/ChangePlan'
import Button from '../../extraComponents/button/Button'
import logoIcon from '../../../../assets/images/logo.svg'
import '../signIn/signin.scss'
import CleengLoader from '../../extraComponents/loader/CleengLoader'

const SignUpSteps = {
  REGISTER: {
    stepNumber: 0,
    nextStep: 1,
  },
  CONSENTS: {
    stepNumber: 1,
    nextStep: 2,
  },
  CHANGEPLAN: {
    stepNumber: 2,
    nextStep: 3,
  },
  PURCHASE: {
    stepNumber: 3,
  },
}

const SignIn = () => {
  const [showLoader, setShowLoader] = useState(false)
  const [actualStep, setActualStep] = useState(0)
  const [offerId, setOfferId] = useState(0)
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const isSubscribed = useSelector((state) => state.auth.isSubscribed)

  useEffect(() => {
    if (actualStep === SignUpSteps.CHANGEPLAN.stepNumber) {
      setShowLoader(false)
    } else {
      // check if cleeng loader is on screen
      const checkLoader = setInterval(() => {
        if (!document.getElementsByClassName('msd__spinner')[0]) {
          clearInterval(checkLoader)
          setShowLoader(false)
        }
      }, 500)
    }
  }, [actualStep])

  const onSuccesfulSignUp = () => {
    dispatch(authActions.login())
    dispatch(
      authActions.setSubscription({
        content: true,
      }),
    )
    navigate('/liveTV')
  }

  const changeStep = (stepNumber) => {
    setActualStep(stepNumber)
    setShowLoader(true)
  }

  useEffect(() => {
    const { state } = location
    if (state?.purchaseSubscription) {
      changeStep(SignUpSteps.CONSENTS.nextStep)
    }
    return () => {
      if (!isSubscribed) Auth.logout()
    }
  }, [])

  return (
    <div className="signIn">
      { actualStep === 2 ? null
        : <Button icon={[logoIcon]} iconAlt="logo" classButton="logo-button" buttonType="logo" /> }

      { showLoader ? <CleengLoader /> : ''}

      {
        actualStep === SignUpSteps.REGISTER.stepNumber
          ? (
            <Register
              onSuccess={() => changeStep(SignUpSteps.REGISTER.nextStep)}
              onHaveAccountClick={() => navigate('/signIn')}
            />
          )
          : ''
      }
      {
        actualStep === SignUpSteps.CONSENTS.stepNumber
          ? (
            <CheckoutConsents
              onSuccess={() => changeStep(SignUpSteps.CONSENTS.nextStep)}
            />
          )
          : ''
      }
      {
        actualStep === SignUpSteps.CHANGEPLAN.stepNumber
          ? (
            <ChangePlan
              isSignUp
              setOfferId={setOfferId}
              onClick={() => changeStep(SignUpSteps.CHANGEPLAN.nextStep)}
            />
          )
          : ''
      }
      {
        actualStep === SignUpSteps.PURCHASE.stepNumber
          ? (
            <Purchase
              offerId={offerId}
              onSuccess={onSuccesfulSignUp}
            />
          )
          : ''
      }
    </div>
  )
}

export default SignIn

/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { FetchScheduleInfo } from '../ScheduleContext'
import Channels from './Channels/Channels'
import Dates from './Hours/Dates'
import Programs from './Program/Programs'
import Skeleton from '../extraComponents/skeleton/Skeleton'
import './schedule.scss'

const Schedule = ({
  isPipModeActive, getPipState, handleSkeleton, isSkeletonActive,
}) => {
  const [programsRef, setRef] = useState()
  const [channelsRef, setChannelRef] = useState()

  const location = useLocation()
  const skeletonOnPipModeClass =
  (location.pathname.includes('schedule') && isSkeletonActive)
  || (isPipModeActive && isSkeletonActive) ? 'schedule-full-screen' : ''

  const handleFullScreen = () => {
    getPipState((current) => !current)
  }
  return (
    <FetchScheduleInfo handleSkeleton={handleSkeleton}>
      <>
        {
        location.pathname.includes('schedule')
          ? <Dates /> : ''
        }
        <div className={`${isSkeletonActive ? 'schedule-with-skeleton' : ''} ${skeletonOnPipModeClass} schedule`}>
          { isSkeletonActive ? <Skeleton isPipActive={isPipModeActive} /> : ''}
          <Channels
            handleSkeleton={handleSkeleton}
            programsRef={programsRef}
            setChannelRef={setChannelRef}
            isPipModeActive={isPipModeActive}
          />
          <Programs
            isPipModeActive={isPipModeActive}
            isSkeletonActive={isSkeletonActive}
            setRef={setRef}
            channelsRef={channelsRef}
            handleFullScreen={handleFullScreen}
          />
        </div>
      </>
    </FetchScheduleInfo>
  )
}

Schedule.propTypes = {
  isPipModeActive: PropTypes.bool,
  getPipState: PropTypes.func,
  handleSkeleton: PropTypes.func,
  isSkeletonActive: PropTypes.bool,
}

Schedule.defaultProps = {
  isPipModeActive: false,
  getPipState: () => { },
  handleSkeleton: () => { },
  isSkeletonActive: false,
}

export default Schedule

/* eslint-disable no-param-reassign */
import { configureStore } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { combineReducers } from 'redux'
import userSlice from './user/user-slice'
import authSlice from './auth/auth-slice'

const combinedReducer = combineReducers({
  user: userSlice.reducer,
  auth: authSlice.reducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    state = undefined
  }
  return combinedReducer(state, action)
}

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

// eslint-disable-next-line import/prefer-default-export
export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
})

// eslint-disable-next-line import/prefer-default-export
export const persistor = persistStore(store)

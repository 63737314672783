/* eslint-disable no-use-before-define */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Routes, Route, Navigate } from 'react-router-dom'
import SmallScreenNavBar from './components/extraComponents/SmallScreenNav/SmallScreenNavbar'
import Navbar from './components/extraComponents/navbar/Navbar'
import SignIn from './components/accountRelated/signIn/SignIn'
import SignUp from './components/accountRelated/signUp/SignUp'
import CodeSignIn from './components/accountRelated/codeSignIn/CodeSignIn'
import LiveHome from './components/homeRelated/home/LiveHome'
import Home from './components/homeRelated/home/Home'
import ScheduleScreen from './components/schedule/ScheduleScreen'
import SettingCleeng from './components/settingsRelated/settings/SettingsCleeng'
import { userActions } from './store/user/user-slice'
import './theme.scss'
import SplashScreen from './components/extraComponents/loader/SplashScreen'
import LoaderScreen from './components/extraComponents/loader/LoaderScreen'
import cleengConfig from './utils/cleengConfig'
import usePageTracking from './utils/usePageTracking'
import { getUserRegion } from './services/account/region.service.tsx'
import languagesJson from '../assets/json/languages.json'

const App = () => {
  const { t, i18n } = useTranslation('common')
  const [showSplash, setShowSplash] = useState(true)
  const [showSpinner, setShowSpinner] = useState(true)
  const [regionFetched, setRegionFetched] = useState(false)
  const [languagesAvailable] = useState(languagesJson)
  const [selectedLanguage, setSelectedLanguage] = useState(0)
  const handleSpinner = (status) => setShowSpinner(status)
  const [isSkeletonActive, setIsSkeletonActive] = useState(false)
  const handleSkeleton = (state) => setIsSkeletonActive(state)
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
  const isSubscribed = useSelector((state) => state.auth.isSubscribed)
  const dispatch = useDispatch()

  useEffect(() => {
    console.log('isSubscribed', isSubscribed)
    console.log('isLoggedIn', isLoggedIn)
    if (isLoggedIn) {
      dispatch(userActions.setShowNavbar(true))
    } else dispatch(userActions.setShowNavbar(false))
  }, [isLoggedIn])
  const handleSelectedLanguage = (value) => setSelectedLanguage(value)
  useEffect(async () => {
    if (isLoggedIn) {
      dispatch(userActions.setShowNavbar(true))
    } else {
      dispatch(userActions.setShowNavbar(false))
    }

    const [statusCode, response] = await getUserRegion()
    if (statusCode === 200) {
      localStorage.setItem('regionID', response.regionID)
      setShowSpinner(false)
      setRegionFetched(true)
    }
    setTimeout(() => {
      setShowSplash(false)
    }, 3000)
  }, [])

  return (
    <>
      { cleengConfig() }
      { usePageTracking() }
      { showSplash ? <SplashScreen /> : ''}
      { showSpinner ? <LoaderScreen /> : ''}
      <Navbar
        languagesAvailable={languagesAvailable}
        selectedLanguage={selectedLanguage}
        handleSelectedLanguage={handleSelectedLanguage}
        t={t}
        i18n={i18n}
      />
      <Routes>
        { !isLoggedIn && (
        <>
          <Route
            path="/"
            element={(
              <Home />
            )}
          />
          <Route
            path="/signin"
            element={(
              <SignIn />
              )}
          />
          <Route
            path="/signUp"
            element={(
              <SignUp />
              )}
          />
          <Route path="*" element={<Navigate to="/signin" />} />
        </>
        )}

        {
            (isLoggedIn && isSubscribed && regionFetched) && (
            <>
              <Route
                path="/tv"
                element={(
                  <CodeSignIn t={t} />
                )}
              />
              <Route
                path="/liveTV"
                element={(
                  <LiveHome
                    isSkeletonActive={isSkeletonActive}
                    handleSkeleton={handleSkeleton}
                    handleSpinner={handleSpinner}
                  />
                )}
              />
              <Route
                path="/schedule"
                element={(
                  <ScheduleScreen
                    isSkeletonActive={isSkeletonActive}
                    handleSkeleton={handleSkeleton}
                    handleSpinner={handleSpinner}
                  />
                )}
              />
              <Route path="*" element={<Navigate to="/liveTV" />} />
            </>
            )
          }

        {
            (isLoggedIn && !isSubscribed) && (
              <>
                <Route
                  path="/signUp"
                  element={(
                    <SignUp />
              )}
                />
                <Route
                  path="*"
                  element={(
                    <Navigate
                      to="/signUp"
                      state={{
                        purchaseSubscription: true,
                      }}
                    />
                  )}
                />

              </>
            )
        }
        <Route
          path="/settings"
          element={(<SettingCleeng />)}
        />
      </Routes>
      <SmallScreenNavBar t={t} />
    </>
  )
}

export default App

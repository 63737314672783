import React from 'react'
import './loader.scss'

const LoaderScreen = () => (
  <div className="splash-screen">
    <div className="spinner" />
  </div>
)

export default LoaderScreen

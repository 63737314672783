/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialAuthState = {
  isLoggedIn: localStorage.getItem('CLEENG_AUTH_TOKEN') !== null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    login(state) {
      state.isLoggedIn = true
    },
    logout(state) {
      state.isLoggedIn = false
    },
    setSubscription: (state, action) => {
      state.isSubscribed = action.payload.content
    },
  },
})

export const authActions = authSlice.actions

export default authSlice

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { userActions } from '../../../store/user/user-slice'
import logoIcon from '../../../../assets/images/logo.svg'
import { activateDevice } from '../../../services/account/activateDevice.service.tsx'
import Button from '../../extraComponents/button/Button'
import './codeSignIn.scss'

const CodeSignIn = () => {
  const [correctLogin, setCorrectLogin] = useState(false)
  const [showError, setShowError] = useState(false)
  const { t } = useTranslation('common')
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(userActions.setShowNavbar(false))
  }, [])

  const focusNext = (e) => {
    const { maxLength, value, name } = e.target
    // eslint-disable-next-line no-unused-vars
    const [fieldName, fieldIndex] = name.split('-')

    const fieldIntIndex = parseInt(fieldIndex, 10)

    // Check if no of char in field == maxlength
    if (value.length >= maxLength) {
      // It should not be last input field
      if (fieldIntIndex < 5) {
        // Get the next input field using it's name
        const nextfield = document.querySelector(
          `input[name=code-${fieldIntIndex + 1}]`,
        )
        // If found, focus the next field
        if (nextfield !== null) {
          nextfield.focus()
        }
      } else if (fieldIntIndex >= 5) {
        const nextButton = document.querySelector('button[name=code-6]')
        nextButton.focus()
      }
    }
  }

  const handleFocus = (event) => event.target.select()

  const handleForm = async () => {
    const form = document.getElementById('code-signin')
    const regex = /[a-zA-Z0-9]/
    let flag = false
    let tempCode = ''
    for (let i = 0; i < 5; i += 1) {
      if (!regex.test(form.elements[i].value) || form.elements[i].value === '') {
        flag = true
      }
      tempCode += form.elements[i].value.toUpperCase()
    }

    setShowError(flag)
    if (flag) return false

    const statusCode = await activateDevice(tempCode)

    setShowError(statusCode !== 200)
    setCorrectLogin(statusCode === 200)

    return true
  }

  return (
    <div className="code-signin">
      <Button icon={[logoIcon]} iconAlt="logo" classButton="logo-button" buttonType="logo" />
      <div
        className="background-wrapper"
        style={{ background: 'linear-gradient(110.33deg, #281547 0%, #141414 61.86%, #67144B 113.12%)' }}
      />
      <div className="code-signin__container">
        <div className="code-signin__title">{t('Activation_Screen.title')}</div>
        <form className="code-input-container" id="code-signin">
          <input name="code-1" onInput={focusNext} onFocus={handleFocus} type="text" className="code-input" maxLength="1" pattern="[a-zA-Z0-9]+" required />
          <input name="code-2" onInput={focusNext} onFocus={handleFocus} type="text" className="code-input" maxLength="1" pattern="[a-zA-Z0-9]+" required />
          <input name="code-3" onInput={focusNext} onFocus={handleFocus} type="text" className="code-input" maxLength="1" pattern="[a-zA-Z0-9]+" required />
          <input name="code-4" onInput={focusNext} onFocus={handleFocus} type="text" className="code-input" maxLength="1" pattern="[a-zA-Z0-9]+" required />
          <input name="code-5" onInput={focusNext} onFocus={handleFocus} type="text" className="code-input" maxLength="1" pattern="[a-zA-Z0-9]+" required />
        </form>
        <div className="invalid-code" style={showError ? {} : { visibility: 'hidden' }}>{t('Activation_Screen.invalid_code')}</div>
        <div className="invalid-code" style={correctLogin ? {} : { visibility: 'hidden' }}>{t('Activation_Screen.tv_success')}</div>
        <div className="button-container">
          <button onClick={() => handleForm()} name="code-6" aria-label="Continue" type="button" className="code-signin__button">{t('Activation_Screen.btn_title')}</button>
        </div>
      </div>
    </div>
  )
}

export default CodeSignIn

/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { userActions } from '../../../store/user/user-slice'
import Player from '../../player/Player'
import { FetchProgramContext } from '../../ProgramContext'
import ProgramInformation from '../../schedule/Program/ProgramInformation'
import Schedule from '../../schedule/Schedule'
import './LiveHome.scss'

const LiveHome = ({ handleSpinner, isSkeletonActive, handleSkeleton }) => {
  const [isPipModeActive, setIsPipModeActive] = useState(false)
  const getPipState = (state) => {
    setIsPipModeActive(state)
  }
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(userActions.setShowNavbar(true))
  }, [])
  return (
    <div className="live-home">
      <FetchProgramContext>
        <Player handleSkeleton={handleSkeleton} isPipModeActive={isPipModeActive} getPipState={getPipState} handleSpinner={handleSpinner} />
        <div className="schedule-separator">
          <Schedule isPipModeActive={isPipModeActive} getPipState={getPipState} isSkeletonActive={isSkeletonActive} handleSkeleton={handleSkeleton} />
        </div>
        <ProgramInformation />
      </FetchProgramContext>
    </div>
  )
}
LiveHome.propTypes = {
  handleSpinner: PropTypes.func,
  isSkeletonActive: PropTypes.bool,
  handleSkeleton: PropTypes.func,
}

LiveHome.defaultProps = {
  handleSpinner: () => { },
  isSkeletonActive: false,
  handleSkeleton: () => { },
}

export default LiveHome

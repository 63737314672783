import { getData } from '../util/appConfigHelper'
import getApiURL from '../util/environmentHelper'
import fetchWithJWT from '../util/fetchHelper'

const getCustomerSubscriptionsAsync = async () => {
  const API_URL = getApiURL()
  const customerId = getData('CLEENG_CUSTOMER_ID') || ''

  const url = `${API_URL}/customers/${customerId}/subscriptions`
  return fetchWithJWT(url, {
    method: 'GET',
  }).then((res) => res.json())
}

export default getCustomerSubscriptionsAsync

import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import faqContainers from '../../../../assets/json/faqItems.json'
import './faq.scss'
import Button from '../../extraComponents/button/Button'
import arrow from '../../../../assets/images/arrowIcon.svg'

const Faq = ({ buttonFunc }) => {
  const { t } = useTranslation('common')
  const heroRef = React.useRef()
  const [active, setActive] = React.useState(0)

  const openItem = (index) => {
    // Expand faq item
    if (active === index) {
      setActive(-1)
    } else {
      setActive(index)
    }
  }

  const closeItem = (index, event) => {
    if (event.key === 'Enter' || event.key === 'Space') {
      openItem(index)
    }
  }

  return (
    <div className="faq">
      <h1>{t('faq.title')}</h1>
      <div className="faq-list" ref={heroRef}>
        {
          faqContainers?.items.map((item, index) => (
            <div
              key={item.id}
              className={`${index === active ? 'item-active' : 'item'}`}
              type="button"
            >
              <button
                className="faq-head"
                onClick={() => openItem(index)}
                onKeyPress={(e) => closeItem(index, e)}
                type="button"
              >
                <h1>
                  {t(`faq.faq_list.${item.question}`)}
                </h1>
                <span>{`${index === active ? '-' : '+'}`}</span>
              </button>
              <div className="information">
                <p>
                  {t(`faq.faq_list.${item.answer}`)}
                </p>
              </div>
            </div>
          ))
        }
      </div>
      <Button classButton="go-to-top-arrow" icon={[arrow]} onClick={buttonFunc} iconAlt="down arrow" />
    </div>
  )
}

Faq.propTypes = {
  buttonFunc: PropTypes.func.isRequired,
}

export default Faq

import { useEffect } from 'react'
import { Config } from '@cleeng/mediastore-sdk'

const cleengConfig = () => {
  useEffect(() => {
    Config.setEnvironment('sandbox')
    Config.setPublisher('898676925')
    Config.setOffer('S531234647_PL')
    Config.setMyAccountUrl('http://localhost:8080/settings')
    Config.setPaypalUrls({
      successUrl: 'http://localhost:8080/settings',
      cancelUrl: 'http://localhost:8080/',
      errorUrl: 'http://localhost:8080/',
    })

    Config.setTheme({
      successColor: '#743ad5',
    })
  }, [])
}

export default cleengConfig

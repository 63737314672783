import React, {
  useCallback, useState, useEffect,
} from 'react'
import { useDispatch, Provider, useSelector } from 'react-redux'
import { MyAccount, store } from '@cleeng/mediastore-sdk'
import logoIcon from '../../../../assets/images/logo.svg'
import Button from '../../extraComponents/button/Button'
import { authActions } from '../../../store/auth/auth-slice'
import { userActions } from '../../../store/user/user-slice'
import { fetchUserSubscription } from '../../../store/auth/auth-actions'
import '../../accountRelated/signIn/signin.scss'
import './settingsCleeng.scss'

const SettingCleeng = () => {
  const [isCleeng, setMyCleeng] = useState(useSelector((state) => state.auth.isLoggedIn))

  const dispatch = useDispatch()

  const obtainSettingsHeight = () => {
    if (document.getElementsByClassName('msd__account-wrapper')[0] && document.getElementsByClassName('navbar')[0]) {
      let emptyPixels = (
        window.visualViewport.width >= 1080
          ? window.visualViewport.height : window.innerHeight)
      - document.getElementsByClassName('navbar')[0].clientHeight
      // If there is responsive navbar (phone)
      if (document.getElementsByClassName('navbar-responsive')[0]) {
        emptyPixels -= document.getElementsByClassName('navbar-responsive')[0].clientHeight
      }
      document.getElementsByClassName('msd__account-wrapper')[0].style.height = `${emptyPixels}px`
      document.getElementsByClassName('msd__account-wrapper')[0].style.minHeight = `${emptyPixels}px`
    }
  }

  useEffect(() => {
    if (isCleeng) {
      dispatch(userActions.setShowNavbar(true))
      dispatch(authActions.login())
      dispatch(fetchUserSubscription()).then(() => {})
      obtainSettingsHeight()
    } else {
      dispatch(userActions.setShowNavbar(false))
      dispatch(authActions.logout())
    }
  }, [isCleeng])

  useEffect(() => {
    const handleResize = () => {
      if (isCleeng) {
        obtainSettingsHeight()
      }
    }
    window.addEventListener('resize', handleResize) // Change resize on screen change
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const observer = new MutationObserver((mutationList) => {
    mutationList.forEach((mutation) => {
      if (mutation?.target?.className?.includes('msd__account__card')) {
        document.getElementsByClassName('msd__account__card')[0].style.display = 'none'
        document.getElementsByClassName('msd__section-header')[0].style.display = 'none'
      }
      if (mutation.type === 'childList') {
        mutation.addedNodes.forEach((element) => {
          if (element?.className?.includes('msd__account-wrapper')) {
            setMyCleeng(true)
            obtainSettingsHeight()
          }
          if (element?.className?.includes('msd__auth-wrapper')) {
            setMyCleeng(false)
          }
        })
      }
    })
  })

  const onRefChange = useCallback((node) => {
    // call `observe()` on that MutationObserver instance,
    // passing it the element to observe, and the options object
    if (node) {
      observer.observe(node, { childList: true, attributes: true, subtree: true })
    }
    return () => { observer.disconnect() }
  }, []) // adjust deps

  return (
    <div className={isCleeng ? 'settings' : 'signIn'} ref={onRefChange}>
      {isCleeng ? null : <Button icon={[logoIcon]} iconAlt="logo" classButton="logo-button" buttonType="logo" />}
      <Provider store={store}>
        <MyAccount />
      </Provider>
    </div>

  )
}

export default SettingCleeng

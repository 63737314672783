import React, { useRef } from 'react'
import Navbar from '../../extraComponents/navbar/Noauthnavbar'
import Hero from '../../carouselRelated/hero/Hero'
import Plans from '../plan/Plans'
import Faq from '../faq/Faq'
import './home.scss'
import AutoScrollCarousel from '../../carouselRelated/autoScrollCarousel/AutoScrollCarousel'
import ExpandingCarousel from '../../carouselRelated/expandingCarousel/ExpandingCarousel'

const Home = () => {
  const topRef = useRef()
  const plansRef = useRef()
  const carouselRef = useRef()
  const expandingRef = useRef()
  const faqRef = useRef()

  const handleHeroScroll = () => {
    carouselRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
  }

  const handleCarouselScroll = () => {
    plansRef.current.scrollIntoView({ behavior: 'smooth', block: (window.innerWidth >= 1080) ? 'center' : 'start' })
  }

  const handlePlansScroll = () => {
    expandingRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
  }

  const expandingPlansScroll = () => {
    faqRef.current.scrollIntoView({ behavior: 'smooth', block: (window.innerWidth >= 1080) ? 'center' : 'start' })
  }

  const handleToTopScroll = () => {
    topRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
  }

  return (
    <>
      <section ref={topRef} className="banner">
        <Navbar />
        <Hero buttonFunc={handleHeroScroll} />
      </section>
      <div className="separator">
        <div ref={carouselRef} className="carousel-separator">
          <AutoScrollCarousel buttonFunc={handleCarouselScroll} />
        </div>
        <div ref={plansRef} className="plans-separator">
          <Plans buttonFunc={handlePlansScroll} />
        </div>
        <div ref={expandingRef} className="expanding-separator">
          <ExpandingCarousel buttonFunc={expandingPlansScroll} />
        </div>
        <div ref={faqRef} className="faqs-separator">
          <Faq buttonFunc={handleToTopScroll} />
        </div>
      </div>
    </>
  )
}
export default Home

/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, {
  useState, useRef, useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Auth } from '@cleeng/mediastore-sdk'
import Button from '../button/Button'
import logoutIcon from '../../../../assets/images/logout.svg'
import avatarIcon from '../../../../assets/images/profile.svg'
import settingsIcon from '../../../../assets/images/settings.svg'
import tv from '../../../../assets/images/tv-white.svg'
import './dropdown.scss'
import { authActions } from '../../../store/auth/auth-slice'

const Dropdown = ({
  closeFunc, title, isForSettings, t, i18n, languagesAvailable, selectedLanguage, handleSelectedLanguage,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [open, setIsOpen] = useState(false)
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const dropdownRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }
    if (open && !isSmallScreen) {
      document.addEventListener('click', handleClickOutside, true)
    }
    return () => document.removeEventListener('click', handleClickOutside, true)
  }, [open, isSmallScreen])

  // Checking if it is a small screen
  useEffect(() => {
    const handleResize = () => {
      if (window.screen.availWidth < 1080) {
        setIsSmallScreen(true)
        setIsOpen(true)
      } else {
        setIsSmallScreen(false)
        setIsOpen(false)
      }
    }

    handleResize() // Change screen size on first render
    window.addEventListener('resize', handleResize) // Change ia on screen change
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const goToSettings = () => {
    if (!isSmallScreen) {
      setIsOpen(false)
    } else if (closeFunc) {
      closeFunc()
    }
    navigate('/settings')
  }

  useEffect(() => {
    if (isSmallScreen && !open) {
      setIsOpen(true)
    }
  }, [])
  const deleteUserTokens = () => {
    // TOKENS ARE SAVED IN LOCALSTORAGE
    localStorage.removeItem('CLEENG_CUSTOMER_IP')
    localStorage.removeItem('CLEENG_CUSTOMER_ID')
    localStorage.removeItem('CLEENG_CUSTOMER_EMAIL')
    localStorage.removeItem('persist:root')
  }
  const logoutHandler = () => {
    const isMyAccount = window.location.pathname.includes('settings')
    Auth.logout(isMyAccount)
    dispatch(authActions.logout())
    deleteUserTokens()
    navigate('/')
  }

  const handleLanguageChange = (id) => {
    if (!isSmallScreen) setIsOpen(false)
    handleSelectedLanguage(id)
  }
  useEffect(() => {
    i18n.changeLanguage(languagesAvailable[selectedLanguage]?.languageCode)
  }, [selectedLanguage])

  return (
    <ul ref={dropdownRef} className={`navbarButton ${isSmallScreen ? 'tabs-section' : 'dropdown'} ${open ? 'open-dropdown' : ''}`}>
      {
        isSmallScreen
          ? <span className="title-section">{isForSettings ? t('links.Account') : t('links.Languages')}</span>
          : (
            <Button
              text={languagesAvailable[selectedLanguage]?.name || title}
              buttonType={(!isForSettings) ? 'language' : 'settings'}
              icon={[languagesAvailable[selectedLanguage]?.flagIcon || avatarIcon]}
              onClick={() => setIsOpen(!open)}
            />
          )
      }
      {
          open
            ? (
              <li className="dropdown-elements">
                {
                  isForSettings ? (
                    <>
                      <Button buttonType="" onClick={() => navigate('/tv')} text={t('links.ActivateTV')} icon={[tv]} />
                      <Button text={t('links.Settings')} onClick={goToSettings} icon={[settingsIcon]} />
                      { !isSmallScreen ? <Button classButton="sign_out_desktop" buttonType="logout" onClick={logoutHandler} text={t('links.Sign_Out')} icon={[logoutIcon]} /> : '' }
                    </>
                  ) : (
                    <>
                      {
                        languagesAvailable.map((value, index) => (
                          <Button
                            key={value.languageID}
                            classButton="language_options"
                            onClick={() => handleLanguageChange(index)}
                            text={t(value.name)}
                            icon={[value.flagIcon]}
                          />
                        ))
                      }
                      {
                        isSmallScreen ? <Button classButton="sign_out" buttonType="logout" onClick={logoutHandler} text={t('links.Sign_Out')} icon={[logoutIcon]} /> : ''
                      }
                    </>
                  )
                }
              </li>
            ) : ''
      }
    </ul>
  )
}

Dropdown.propTypes = {
  closeFunc: PropTypes.func,
  title: PropTypes.string,
  isForSettings: PropTypes.bool,
  t: PropTypes.func,
  i18n: PropTypes.shape({
    language: PropTypes.string,
    changeLanguage: PropTypes.func,
  }),
  languagesAvailable: PropTypes.arrayOf(PropTypes.shape({
    languageID: PropTypes.string,
    languageCode: PropTypes.string,
    name: PropTypes.string,
    flagIcon: PropTypes.string,
  })),
  selectedLanguage: PropTypes.number,
  handleSelectedLanguage: PropTypes.func,
}

Dropdown.defaultProps = {
  closeFunc: undefined,
  title: undefined,
  isForSettings: false, // IF TRUE THEN IT IS AN ACCOUNT DROPDOWN ELSE IT IS A LANGUAGE SELECTOR
  t: undefined,
  i18n: undefined,
  languagesAvailable: undefined,
  selectedLanguage: 0,
  handleSelectedLanguage: () => {},
}

export default Dropdown

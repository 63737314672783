import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import './skeleton.scss'

const Skeleton = ({ isPipActive }) => {
  const location = useLocation()
  return (
    <div className="skeleton-wrapper">

      <div className="skeleton-body">
        <div className="card__body body__text">
          <div className="skeleton skeleton-text skeleton-text-body" />
        </div>
      </div>

      <div className="skeleton-footer-wrapper">
        <div className={`skeleton-text skeleton-footer-cards ${!location.pathname.includes('schedule') ? ` skeleton-left-side ${isPipActive ? 'skeleton-left-side-pip' : ''} ` : 'skeleton-footer-cards-schedule skeleton-left-side'}`} />
        <div className={`skeleton-text skeleton-footer-cards ${!location.pathname.includes('schedule') ? `${isPipActive ? 'skeleton-left-side-pip' : ''} ` : 'skeleton-footer-cards-schedule'}`} />
      </div>
    </div>
  )
}
Skeleton.propTypes = {
  isPipActive: PropTypes.bool,
}

Skeleton.defaultProps = {
  isPipActive: false,
}

export default Skeleton

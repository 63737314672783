// eslint-disable-next-line import/prefer-default-export
export const InterSectionObserver = (lazyVideo) => {
  if ('IntersectionObserver' in window) {
    const lazyObserver = new IntersectionObserver((entries) => {
      entries.forEach((videoPlayer) => {
        if (videoPlayer.isIntersecting) { // CHECK IF IT IS BEING OBSERVED
        // eslint-disable-next-line guard-for-in, no-restricted-syntax
          for (const source in videoPlayer.target.children) {
            const signSource = videoPlayer.target.children[source]
            if (signSource.tagName === 'SOURCE'
          && typeof signSource.tagName === 'string') {
              signSource.src = 'https://drive.google.com/uc?export=preview&id=1x_lRq3gtG-TCizRLaSIP198ymDHHyxVL'
            }
          }
          videoPlayer.target.load()
          lazyObserver.unobserve(videoPlayer.target)
        }
      })
    })
    lazyObserver.observe(lazyVideo) // OBSERVE VIDEO TAG
  }
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import './plan.scss'
import { useNavigate } from 'react-router-dom'

const Plan = ({ plan }) => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()

  return (
    <div className={`plan-container-border  ${t(`plan.plans_list.${plan.priority}`) === 1 ? 'best' : ''}`} id={plan.id}>
      <div className="plan-container">
        <div className="plan-information">
          <p className="plan-title">{t(`plan.plans_list.${plan.name}`)}</p>
          <h1 className="plan-cost">{t(`plan.plans_list.${plan.cost}`)}</h1>
          <p className="plan-description">
            {t(`plan.plans_list.${plan.description}`)}
          </p>
        </div>
        <button type="button" className={`${t(`plan.plans_list.${plan.priority}`) === 1 ? 'best' : ''}`} onClick={() => navigate('/signup')}>{t('plan.get')}</button>
      </div>
    </div>
  )
}

Plan.propTypes = {
  plan: PropTypes.shape({
    id: PropTypes.string.isRequired,
    priority: PropTypes.string,
    description: PropTypes.string.isRequired,
    cost: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
}

Plan.defaultProps = {
  plan: {
    priority: '0',
  },
}

export default Plan

/* eslint-disable no-use-before-define */
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import carouselsJson from '../../../../assets/json/channels.json'
import Button from '../../extraComponents/button/Button'
import arrow from '../../../../assets/images/arrowIcon.svg'
import useScreen from '../../../utils/Observer'
import './AutoScrollCarousel.scss'

const AutoScrollCarousel = ({ buttonFunc }) => {
  const { t } = useTranslation('common')
  const previewRef = React.useRef()
  const previewContainerRef = React.useRef()
  // SAFARI ONLY SUPPORTS threshold LOWER THAN 1
  const observer = useScreen(previewContainerRef, 0.50)

  const [active, setActive] = React.useState(0)
  const [carouselContainer, setcarouselContainer] = useState([])
  const [notIsDesktop, setNotIsDesktop] = React.useState(false)
  const navigate = useNavigate()
  // const dispatch = useDispatch()
  const autoScroll = useRef(undefined)
  const timers = useRef(0)

  useEffect(() => {
    setcarouselContainer(carouselsJson)
  }, [carouselsJson])

  /**
   * Observer for the carousel:
   *  observer true if user is watching the carousel
   *  observer false if user is not watching the carousel
   * */
  useEffect(() => {
    if (observer) {
      autoScroll.current = setInterval(() => {
        setActive((oldActive) => (oldActive + 1) % Math.ceil(carouselsJson.length))
      }, 3000)
    }
    return () => clearInterval(autoScroll.current)
  }, [observer])

  useEffect(() => {
    const scrollExtraValue = notIsDesktop ? 5 : 0
    const scrollExtra = active === carouselsJson.length - 1 ? scrollExtraValue : 0
    previewRef.current.scrollLeft = Math.ceil((notIsDesktop
      ? 347 : 0.32 * previewRef.current.offsetWidth)
    * active) - scrollExtra
  }, [active])

  // handle image aspect ratio
  useEffect(() => {
    const handleResize = () => {
      if (window.screen.availWidth < 1080) {
        setNotIsDesktop(true)
      } else {
        setNotIsDesktop(false)
      }
    }

    handleResize() // Change screen on first render
    window.addEventListener('resize', handleResize) // Change screen variable on screen change
    // handle EVENTS DO NOT BLOCK THE PAGE SCROLLING (NO PAGE SCROLLING DELAY)
    window.addEventListener('touchstart', handleTouchStart, { passive: true })
    window.addEventListener('mousewheel', handleTrackpadStart, { passive: true })
    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('touchstart', handleTouchStart, { passive: true })
      window.removeEventListener('mousewheel', handleTrackpadStart, { passive: true })
    }
  }, [])

  const handleTimer = () => {
    timers.current += 1
    clearInterval(autoScroll.current)
    autoScroll.current = undefined
    setTimeout(() => {
      timers.current -= 1
      if (timers.current === 0) {
        // Resuming the auto scroll after 5 seconds
        setActive((oldActive) => (oldActive + 1) % Math.ceil(carouselsJson.length))
        autoScroll.current = setInterval(() => {
          setActive((oldActive) => (oldActive + 1) % Math.ceil(carouselsJson.length))
        }, 5000)
      }
    }, 5000)
  }

  const goNext = () => {
    handleTimer()
    // Move to hero item to the right
    setActive((oldActive) => (oldActive + 1) % Math.ceil(carouselsJson.length))
  }

  const goBack = () => {
    handleTimer()
    // Move to hero item to the left
    setActive((oldActive) => (oldActive !== 0
      ? oldActive - 1
      : Math.ceil(carouselsJson.length) - 1))
  }

  let trackpadEnabled = true

  const handleTrackpadStart = ({ target, deltaX }) => {
    if (!trackpadEnabled || Math.abs(deltaX) < 30 || target.classList[0] !== 'preview-item') return
    if (deltaX > 30) {
      trackpadEnabled = false
      setTimeout(() => {
        trackpadEnabled = true
      }, 500)
      goNext()
    } else if (deltaX < -30) {
      trackpadEnabled = false
      setTimeout(() => {
        trackpadEnabled = true
      }, 500)
      goBack()
    }
  }

  let positionX = 0

  const handleTouchStart = ({ changedTouches, target }) => {
    if (target.classList[0] !== 'preview-item') return
    handleTimer()
    positionX = changedTouches[0]?.clientX
    window.addEventListener('touchend', handleTouchEnd)
  }

  const handleTouchEnd = ({ changedTouches }) => {
    const horizontalMovement = (changedTouches[0]?.clientX || 0) - positionX
    if (horizontalMovement > 50) {
      goBack()
    } else if (horizontalMovement < -50) {
      goNext()
    }
    window.removeEventListener('touchend', handleTouchEnd)
  }

  const redirectTo = () => {
    navigate('/signIn')
  }

  return (
    <div className="preview" ref={previewContainerRef}>
      <h1 className="preview-title">
        {t('autoScroll.title')}
      </h1>
      <div className="preview-list" ref={previewRef}>
        {
            carouselContainer?.map((item, index) => (
              <button
                key={item.id}
                alt="preview-item"
                className="preview-item"
                onClick={() => redirectTo()}
                type="button"
                style={{ left: `${index * (notIsDesktop ? 26 : 32)}${(notIsDesktop ? 'em' : '%')}`, background: `linear-gradient(180deg, rgba(5,5,5,0) 0%, black 100%), url(${item.biggerLogo})` }}
              >
                <h1 className="preview-item-label">{item.name}</h1>
              </button>
            ))
        }
        <div style={{ left: `${(carouselsJson.length - 1) * (notIsDesktop ? 26 : 32)}${(notIsDesktop ? 'em' : '%')}` }} className="blank-space" />
      </div>
      <Button classButton="go-to-arrow" icon={[arrow]} onClick={buttonFunc} iconAlt="down arrow" />
    </div>
  )
}

AutoScrollCarousel.propTypes = {
  buttonFunc: PropTypes.func.isRequired,
}

export default AutoScrollCarousel

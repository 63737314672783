/* eslint-disable max-len */
import React, { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import { FetchProgramContext } from '../ProgramContext'
import ProgramInformation from './Program/ProgramInformation'
// import Schedule from './Schedule'
const Schedule = lazy(() => import('./Schedule'))

const ScheduleScreen = ({ handleSpinner, handleSkeleton, isSkeletonActive }) => (
  <Suspense fallback={<>...</>}>
    <div className="schedule-screen">
      <FetchProgramContext>
        <ProgramInformation isModeSchedule />
        <div className="schedule-separator">
          <Schedule handleSpinner={handleSpinner} handleSkeleton={handleSkeleton} isSkeletonActive={isSkeletonActive} />
        </div>
      </FetchProgramContext>
    </div>
  </Suspense>
)

ScheduleScreen.propTypes = {
  handleSpinner: PropTypes.func,
  handleSkeleton: PropTypes.func,
  isSkeletonActive: PropTypes.bool,
}

ScheduleScreen.defaultProps = {
  handleSpinner: () => {},
  handleSkeleton: () => {},
  isSkeletonActive: false,
}

export default ScheduleScreen

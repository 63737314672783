/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Channel from './Channel'
import { returnScheduleContext } from '../../ScheduleContext'
import './channel.scss'

const Channels = ({ setChannelRef, isPipModeActive, handleSkeleton }) => {
  const channelsList = React.useRef()
  const {
    scheduleJson, channelHeight, hoursHeight,
    paddingSize, getEmptySpaceHeight, quantToShow,
  } = returnScheduleContext()

  /**
   * Scroll channels
   */
  useEffect(() => {
    if (channelsList.current) {
      setChannelRef(channelsList.current)
    }
  }, [channelsList])

  useEffect(() => {
    handleSkeleton(true)
    setTimeout(() => {
      getEmptySpaceHeight(isPipModeActive)
      handleSkeleton(false)
    }, 500)
  }, [channelHeight, isPipModeActive])

  // handle image aspect ratio
  useEffect(() => {
    const handleResize = () => {
      handleSkeleton(true)
      setTimeout(() => {
        getEmptySpaceHeight(isPipModeActive)
        handleSkeleton(false)
      }, 500)
    }

    handleResize() // Chech resize in first render
    window.addEventListener('resize', handleResize) // Change resize on screen change
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className="left-column">
      <div className="top-container" style={{ height: hoursHeight, width: channelHeight }} />
      <div className="channels-list" style={{ maxHeight: (channelHeight * quantToShow) }} ref={channelsList}>
        {
        scheduleJson?.map((channel) => (
          <Channel
            key={channel.id}
            channelInfo={channel}
            channelHeight={channelHeight}
            paddingSize={paddingSize}
          />
        ))
      }
      </div>
      <div className="undershadow" style={{ height: (channelHeight * quantToShow) + hoursHeight, width: channelHeight }} />
    </div>
  )
}

Channels.propTypes = {
  setChannelRef: PropTypes.func.isRequired,
  isPipModeActive: PropTypes.bool,
  handleSkeleton: PropTypes.func,
}

Channels.defaultProps = {
  isPipModeActive: false,
  handleSkeleton: () => { },
}

export default Channels

import React from 'react'
import './loader.scss'

const CleengLoader = () => (
  <div className="cleeng-loader-container">
    <div className="loader" />
  </div>
)

export default CleengLoader

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import languageDetector from 'i18next-browser-languagedetector'
import commonEs from '../translations/es/common.json'
import commonEn from '../translations/en/common.json'
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(languageDetector)
  .init({
    interpolation: { escapeValue: false }, // React already does escaping
    lng: ['en', 'es'], // language to use
    resources: {
      en: {
        common: commonEn, // 'common' is our custom namespace
      },
      es: {
        common: commonEs,
      },
    },
  })

export default i18n

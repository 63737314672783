/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
// import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { authActions } from '../../../store/auth/auth-slice'
import './button.scss'

const Button = ({
  iconAlt, icon, text, route, buttonType, onClick, classButton, iconClass, title, closeFunc, isSmallScreen,
}) => {
  const [className, setClassName] = useState('')
  const iconType = className.includes('selected') ? icon[1] : icon[0]
  const location = useLocation()
  const navigate = useNavigate()
  // const { t } = useTranslation('common')
  const isSubscribed = useSelector((state) => state.auth.isSubscribed)
  const dispatch = useDispatch()

  const buttonBehaviour = () => {
    if (buttonType === 'logo') {
      navigate('/')
    } else if (onClick) {
      onClick()
    } else if ((route.toLowerCase() === 'livetv' || route.toLowerCase() === 'schedule') && !isSubscribed) {
      dispatch(authActions.logout())
      navigate('/signUp', {
        state: {
          purchaseSubscription: true,
        },
      })
    } else if (closeFunc) {
      navigate(`/${route.toLowerCase().replace(/\s/g, '')}`)
      closeFunc()
    } else {
      navigate(`/${route.toLowerCase().replace(/\s/g, '')}`)
    }
  }

  useEffect(() => {
    if (location.pathname.toLowerCase().includes(route?.toLowerCase())
      || ((buttonType === 'settings' || buttonType === 'language') && location.pathname.toLowerCase().includes('settings'))
    ) {
      setClassName((isSmallScreen) ? 'active responsivebutton selected' : (buttonType === 'language') ? 'active sel' : 'active') // AS A RESPONSIVE NAVBAR
    } else {
      setClassName((isSmallScreen) ? 'active responsivebutton' : (buttonType === 'language') ? 'active sel' : '') // AS A SIDE BAR
    }
  }, [location.pathname])

  return (
    <button title={title} type="button" className={classButton || className} onClick={() => buttonBehaviour()}>
      { icon.length > 0 ? <img className={iconClass} alt={iconAlt} src={iconType} /> : '' }
      { text === 'avatars' || !text ? '' : <span className={(isSmallScreen) ? 'responsive-text' : ''}>{text}</span> }
    </button>
  )
}

Button.propTypes = {
  iconAlt: PropTypes.string,
  icon: PropTypes.arrayOf(PropTypes.string),
  text: PropTypes.string,
  buttonType: PropTypes.string,
  onClick: PropTypes.func,
  classButton: PropTypes.string,
  iconClass: PropTypes.string,
  title: PropTypes.string,
  closeFunc: PropTypes.func,
  isSmallScreen: PropTypes.bool,
  route: PropTypes.string,
}

Button.defaultProps = {
  iconAlt: undefined,
  icon: [],
  text: undefined,
  buttonType: undefined,
  onClick: undefined,
  classButton: undefined,
  iconClass: '',
  title: undefined,
  closeFunc: undefined,
  isSmallScreen: false,
  route: undefined,
}

export default Button

/* eslint-disable import/no-duplicates */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './navbar.scss'
import Button from '../button/Button'
import tabsJson from '../../../../assets/json/tabs.json'
import openMenuIcon from '../../../../assets/images/open-menu-bar.svg'
import openCloseIcon from '../../../../assets/images/close-menu-2.svg'
import Dropdown from '../dropdown/Dropdown'
import logoIcon from '../../../../assets/images/logo.svg'

const Navbar = ({
  t, i18n, languagesAvailable, selectedLanguage, handleSelectedLanguage,
}) => {
  const [open, setOpen] = React.useState(false)
  const location = useLocation()
  const [tabs, setTabs] = useState([])
  const showNavbar = useSelector((state) => state.user.showNavbar)

  useEffect(() => {
    if (!showNavbar) setOpen(false)
  }, [showNavbar])

  const openMenu = () => {
    setOpen(!open)
  }

  useEffect(() => {
    setTabs(tabsJson)
  }, [])

  return (
    showNavbar
    && (
      <div className={`${open ? 'navbar-menu-mobile' : 'navbar'}`}>
        <div className="navbar-menu-mobile-header">
          <div className="navbar-open-icon-container">
            <button
              className="open-icon"
              type="button"
              onClick={() => openMenu()}
            >
              <img src={open ? openCloseIcon : openMenuIcon} alt="menu icon" />
            </button>
          </div>
        </div>
        <Button classButton="logo-button" icon={[logoIcon]} iconAlt="logo" buttonType="logo" />
        <div className="navbar-menu-mobile-container">
          <div className="left-container">
            {
              tabs.map((section) => (

                section.tabs?.length > 0 ? (

                  <section className="navbarButton tabs-section" key={section.title}>
                    <span className="title-section">{t(`common.${section.title}`)}</span>
                    {
                      section.tabs?.map((tab) => (
                        <Button
                          closeFunc={open ? () => setOpen(false) : () => {}}
                          iconAlt={tab.tabName}
                          key={tab.tabId}
                          icon={[tab.icon]}
                          text={t(`links.${tab.tabName}`)}
                          buttonType="tabRedirect"
                          route={tab.tabName}
                        />
                      ))
                    }
                  </section>
                ) : ''
              ))
            }
          </div>
          <div className="dropdown-section">
            <Dropdown languagesAvailable={[]} t={t} i18n={i18n} isForSettings title={t('settings.account.title')} closeFunc={open ? () => setOpen(false) : () => {}} />
            {
              location.pathname.includes('settings')
                ? (
                  <Dropdown handleSelectedLanguage={handleSelectedLanguage} languagesAvailable={languagesAvailable} selectedLanguage={selectedLanguage} t={t} i18n={i18n} title="Language" closeFunc={open ? () => setOpen(false) : () => {}} />
                )
                : ''
            }
          </div>
        </div>
      </div>
    )
  )
}

export default Navbar

Navbar.propTypes = {
  t: PropTypes.func,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }),
  languagesAvailable: PropTypes.arrayOf(PropTypes.shape({
    languageID: PropTypes.string,
  })),
  selectedLanguage: PropTypes.number,
  handleSelectedLanguage: PropTypes.func,
}

Navbar.defaultProps = {
  t: undefined,
  i18n: undefined,
  languagesAvailable: undefined,
  selectedLanguage: 0,
  handleSelectedLanguage: () => {},
}
